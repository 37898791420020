export function getEnvironmentFromUrl() {
  const url = window.location.href;
  let environment = "newqa"; // Default value

  // Check if the URL is localhost
  if (url.includes("localhost")) {
    // If it's localhost, the environment is 'newqa'
    environment = "newqa";
  } else {
    // If not localhost, extract the environment using regex
    const regex = /https:\/\/funk-(\w+)\.jukinmedia.com\/licensing\//;
    const matches = url.match(regex);

    if (matches && matches.length > 1) {
      environment = matches[1];
    }
  }

  return environment;
}
